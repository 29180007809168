<template>
  <div id="key-lottoset">
    <div class="card alert-primary border-primary mb-3">
      <div class="card-body p-3">
        <h4 class="text-center text-primary">ระบุตัวเลข 4 หลัก</h4>
        <div ref="inputNumbers" class="key-lottoset-numbers">
          <b-form-input
            class="form-control input-lottoset-number"
            maxlength="1"
            v-model="inputNumbers.a"
            v-on:keydown="keydown"
          />
          <b-form-input
            class="form-control input-lottoset-number"
            maxlength="1"
            v-model="inputNumbers.b"
            v-on:keydown="keydown"
          />
          <b-form-input
            class="form-control input-lottoset-number"
            maxlength="1"
            v-model="inputNumbers.c"
            v-on:keydown="keydown"
          />
          <b-form-input
            class="form-control input-lottoset-number"
            maxlength="1"
            v-model="inputNumbers.d"
            v-on:keydown="keydown"
          />
        </div>

        <div class="mt-4" v-if="ticket.rows.length">
          <table class="table table-lottoset-numbers mb-0">
            <thead>
              <tr>
                <th class="text-center" width="50%">ชุดเลข</th>
                <th colspan="2">จำนวนชุด</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in ticket.rows" :key="index">
                <td class="text-center" style="font-size: 120%; color: #000000; font-weight: 600;">{{item.number}}</td>
                <td>
                  <b-form-input size="sm" v-model="item.qty" :number="true" style="width: 100px"/>
                </td>
                <td class="text-right">
                  <span class="btn-delete" style="cursor: pointer;" @click="toggleRemoveBill(index)">
                    <i class="far fa-trash-alt text-danger bigger-175"></i>
                  </span>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td class="text-info text-center">กรอกเท่ากัน</td>
                <td colspan="2">
                  <b-form-input size="sm" v-model="inputQty" :number="true" style="width: 100px"/>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col">
        <div class="d-flex justify-content-center mb-2">
          <label for="inlineFormCustomSelectPref" class="my-1 mr-2">หมายเหตุ:</label>
          <input type="text" class="form-control" v-model="ticket.remarks">
        </div>
      </div>
      <div v-if="roundIcon" class="col-auto">
        <div class="text-right">
          <img :src="roundIcon" height="35" class="shadow-sm float-right">
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-2"></div>
      <div class="col-8">
        <h5 class="text-center mb-0">
          <span> [{{roundMarketName}}]</span>
          <span> {{roundDate}}</span>
        </h5>
        <h4 class="text-center">รวม {{ticketAmount | amountFormat(2, '0.00')}} บาท</h4>
      </div>
      <div class="col-2">
        <h6 class="text-danger text-right time-right">
          <span>เหลือเวลา</span>
          <span> {{roundTimer}}</span>
        </h6>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-auto">
        <button class="btn btn-danger" @click="toggleClearBills">ล้างตาราง</button>
      </div>
      <div class="col-auto">
        <button class="btn btn-primary" @click="preSaveTicket">บันทึก</button>
      </div>
    </div>
  </div>
</template>
<script>
import moment from '@/helpers/moment'
import Swal from 'sweetalert2'
import cryptoRandomString from 'crypto-random-string'

export default {
  name: 'LottosetKeyNumbers',
  props: ['round', 'roundTimer', 'ticket'],
  data() {
    return {
      elements: null,
      inputNumbers: {
        a: '',
        b: '',
        c: '',
        d: ''
      },
      inputQty: ''
    }
  },
  computed: {
    roundMarketName() {
      if(!this.round)
        return ''

      return this.round?.note.marketTitle
    },
    roundDate() {
      if(!this.round)
        return ''

      return moment(this.round.roundDate.date).format("DD-MM-YYYY")
    },
    roundIcon() {
      if(!this.round)
        return false

      return this.round?.market?.imageIcon || false
    },
    fullnumbers() {
      return `${this.inputNumbers.a}${this.inputNumbers.b}${this.inputNumbers.c}${this.inputNumbers.d}`
    },
    cursorPos() {
      return this.fullnumbers.length
    },
    ticketAmount() {
      return this.ticket.rows.reduce((total, item) => {
        total += (item.price * item.qty)
        return total
      }, 0)
    }
  },
  watch: {
    inputQty(n, o) {
      if(n > 0) {
        this.ticket.rows = this.ticket.rows.map((item)=>{
          item.qty = n
          return item
        })
      }
    }
  },
  methods: {
    keydown(event) {
      const pos = this.cursorPos < 3 ? this.cursorPos : 3
      if(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(event.key)) {
        if(pos === 0) {
          this.inputNumbers.a = event.key
          this.elements[1].focus()
        }else
        if(pos === 1) {
          this.inputNumbers.b = event.key
          this.elements[2].focus()
        }else
        if(pos === 2){
          this.inputNumbers.c = event.key
          this.elements[3].focus()
        }else {
          this.inputNumbers.d = event.key
          this.elements[0].focus()
          setTimeout(()=>{
            this.addNumbers(this.fullnumbers)
          }, 100)
        }
      }else
      if(event.key === 'Backspace') {
        if(pos === 3) {
          this.inputNumbers.c = ''
          this.elements[2].focus()
        }else
        if(pos === 2) {
          this.inputNumbers.b = ''
          this.elements[1].focus()
        }else
        if(pos === 1) {
          this.inputNumbers.a = ''
          this.elements[0].focus()
        }
      }

      event.preventDefault()
    },
    addNumbers(number) {
      this.ticket.rows.push({
        type: 'lottoset',
        text: 'เลขชุด',
        number: number,
        qty: 1,
        price: this.round.lottoset.price
      })

      this.inputNumbers = {
        a: '',
        b: '',
        c: '',
        d: ''
      }
    },
    toggleRemoveBill(index) {
      this.ticket.rows.splice(index, 1)
      this.elements[0].focus()
    },
    toggleClearBills() {
      this.inputNumbers = {
        a: '',
        b: '',
        c: '',
        d: ''
      }
      this.ticket.rows = []
      this.ticket.remarks = ''
      this.elements[0].focus()
    },
    preSaveTicket() {
      if(!this.ticket.rows.length) {
        this.elements[0].focus()
        return Swal.fire({
          text: 'กรุณากรอกชุดตัวเลข',
          icon: 'warning',
          confirmButtonText: 'OK'
        })
      }

      this.ticket.uniqueCode = cryptoRandomString({length: 12, type: 'alphanumeric'})
      this.$emit('ticket', this.ticket)
    }
  },
  mounted() {
    this.elements = this.$refs.inputNumbers.getElementsByClassName('input-lottoset-number')
    if(this.elements) {
      this.elements[0].focus()
    }
  }
}
</script>
<style lang="scss">
#key-lottoset {
  .key-lottoset-numbers {
    display: flex;
    justify-content: center;

    .input-lottoset-number {
      width: 55px;
      height: 55px;
      padding: 0px;
      margin: 0 4px;
      line-height: 1;
      font-size: 50px;
      text-align: center;
    }
  }

  .table-lottoset-numbers {
    background-color: #FFFFFF;
    thead {
      tr {
        th {
          font-weight: normal;
        }
      }
    }
    tbody, tfoot {
      tr {
        td {
          vertical-align: middle;
        }
      }
    }
  }
}
</style>
