<template>
  <div class="card alert-info border-info mb-3">
    <div class="card-body p-3">
      <div class="d-flex justify-content-between">
        <div>
          <h3 class="mb-1">{{roundMarketName}}</h3>
          <h6 class="mb-1 text-secondary">ราคา {{price | amountFormat}} บาท/ชุด</h6>
          <h6 class="mb-0 text-secondary">ปิดรับ {{closeTime}} น.</h6>
        </div>
        <div>
          <div>{{roundDate}}</div>
          <div v-if="roundIcon" class="float-right">
            <img :src="roundIcon" style="width: 90px;">
          </div>
        </div>
      </div>

      <div class="mt-2">
        <table class="table table-info table-sm mb-0 table-hover">
          <thead>
            <tr>
              <th width="50%">ประเภท</th>
              <th width="50%">เงินรางวัล</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="prize in prizes">
              <td>{{prize.text}}</td>
              <td>{{prize.prize | amountFormat(0)}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import moment from '@/helpers/moment'
import { marketTypes } from '@/configs/market.config'

export default {
  name: 'LottoInfo',
  props: ['round'],
  computed: {
    roundMarketName() {
      if(!this.round)
        return ''

      return this.round?.note.marketTitle
    },
    roundDate() {
      if(!this.round)
        return ''

      return moment(this.round.roundDate.date).format("DD-MM-YYYY")
    },
    roundIcon() {
      if(!this.round)
        return false

      return this.round?.market?.imageIcon || false
    },
    closeTime() {
      if(!this.round)
        return ''

      return moment(this.round.roundDate.close).format("DD/MM/YYYY HH:mm")
    },
    price() {
      if(!this.round)
        return 0

      return this.round.lottoset.price
    },
    prizes() {
      const marketType = marketTypes.find((t)=>{
        return t.code === this.round?.market?.marketType
      })

      const set = (marketType?.sets || []).find((s)=>{
        return s.set === this.round?.market?.marketSet
      })

      return (set?.openBets || [])
      .filter((bet)=>{
        return this.round?.market?.openBets?.[bet.code]
      })
      .map((bet)=>{
        return {
          ...bet,
          prize: this.round?.lottoset?.prizes?.[bet.code] || 0
        }
      })
    }
  }
}
</script>
